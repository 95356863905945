<template>
  <q-card flat bordered v-ripple:primary class="fit row justify-center cursor-pointer q-hoverable ">
      <span class="q-focus-helper"></span>
      <q-skeleton height="35px" width="200px" v-if="loading" class="q-mx-lg q-my-md" />
      <q-card-section horizontal v-else>
        <q-card-section>
          <div class="text-h6">{{ count }} - {{ label }}</div>
        </q-card-section>
        <q-card-actions vertical class="justify-center q-px-md">
          <q-icon :name="icon" color="primary" size="md" />
        </q-card-actions>
      </q-card-section>
    </q-card>
</template>
<script>
import { defineComponent, onMounted, ref } from 'vue'
import useApi from '../composables/UseApi'

export default defineComponent({
  name: 'CardDashboard',
  props: {
    table: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true,
      default: 'dashboard'
    }
  },
  setup (props) {
    const count = ref(0)
    const loading = ref(false)
    const { fetchCount } = useApi()

    onMounted(async () => {
      loading.value = true
      const response = await fetchCount(props.table)
      count.value = response.count
      loading.value = false
    })
    return { count, loading }
  }
})
</script>
